@import url(https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap);
/* TODO Add site wide styles */
body{
    font-family: 'Nanum Gothic', sans-serif;
    overflow: hidden;
}

.home-container{
    background: url('https://hike-track-app.s3-us-west-2.amazonaws.com/IMG_6864.JPG');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.7);
    background-blend-mode: multiply;
}

.slider{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

.slide{
    position: relative;
    min-width: 100%;
    height: 100%;
    transition: 0.5s
}

#left{
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 10%;
    height: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    font-size: 40px;

}

#right{
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 10%;
    height: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    font-size: 40px;

}

#right:hover, #left:hover{
    background: rgba(0, 0, 0, 0.678);
    transition: 0.5s
}

.active{
    background-color: red;
}

.nav{
    padding: 0 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
}

.nav--hidden{
    display: none;
}

.react-pdf__Document{
    margin-top: 20px;
}

.links:hover{
    text-decoration: underline;
}


 
